import React from "react"
import Select from "react-select"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import Theme from "../../../styles/theme"

const customStyles = {
  container: (provided) => ({
    ...provided,
    marginLeft: 2,
  }),
  control: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderRadius: state.selectProps.borderRadius,
    borderColor: state.selectProps.showError
      ? Theme.color.alert
      : Theme.color.iceBlue,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"

    return { ...provided, opacity, transition }
  },
}

const CustomSelect = ({
  formKey,
  value,
  lngKey,
  items,
  handleChange,
  showError,
  isMultiple,
  defaultValue,
}) => {
  const { t } = useTranslation()

  React.useEffect(() => {
    if (defaultValue && !value) {
      handleChange(formKey, defaultValue.value)
    }
  }, [])

  return (
    <Select
      styles={customStyles}
      value={items.find((c) => c.value === value) || null}
      label={t(lngKey)}
      options={items}
      onChange={(option) =>
        isMultiple
          ? handleChange(formKey, option)
          : handleChange(formKey, option.value)
      }
      placeholder={t(lngKey)}
      width="100%"
      borderRadius="25px"
      showError={showError}
      isMulti={isMultiple}
    />
  )
}

CustomSelect.defaultProps = {
  showError: false,
  value: "",
  defaultValue: "",
  isMultiple: false,
}

CustomSelect.propTypes = {
  formKey: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.shape(),
  ]),
  value: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.shape(),
  ]),
  lngKey: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleChange: PropTypes.func.isRequired,
  showError: PropTypes.bool,
  isMultiple: PropTypes.bool,
}

export default CustomSelect
