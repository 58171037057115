/* eslint-disable no-console */
import app from "firebase/app"
import get from "lodash.get"
import "firebase/auth"
import "firebase/database"
import "firebase/firestore"
import "firebase/storage"
import "firebase/analytics"
import "firebase/remote-config"
import moment from "moment"
import config from "./config"

export const localConfiguration = {
  app__minPasswordSize: "6",
  app__firebaseProject: "us-central1-docv3-develop.cloudfunctions.net",
  app__urlScheme: "https",
  app__environment: "DEVELOP",
  app__preferredCountries: "CR,PE,CL,EC,SV,GT,HN,MX,PA,BO,DO,NI,CO",
  app__supportEmail: "soporte@doc.cr",
  app__allowedRoles: "client",
  app__searchResultsCount: "20",
  app__searchResultsIncrement: "20",
  app__supportedLanguages: "es,en",
  app__slotDuration: "30",
  web__docLanding: "doc.cr",
  web__docWebsite: "docv3-develop.web.app",
  search__pass: null,
  search__user: null,
  search__core: "providers",
  search__path: "solr",
  search__scheme: "https",
  search__port: "443",
  search__server: "search-develop.fg.cr",
}

class Firebase {
  constructor() {
    app.initializeApp(config)
    this.auth = app.auth()
    this.db = app.database()
    this.firestore = app.firestore()
    this.storage = app.storage()
    this.analytics = app.analytics()
    this.remoteConfig = app.remoteConfig()
    this.remoteConfig.settings.minimumFetchIntervalMillis = 3600000
    this.remoteConfig.defaultConfig = localConfiguration
    this.remoteConfig.fetchAndActivate().then(() => {
      console.log("Remote config fetched and activated")
    })
  }

  // *** Auth API ***
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doSignOut = async (providerId) => {
    try {
      const online = this.db.ref("online")
      await online.set({
        [providerId]: false,
      })
      await this.auth.signOut()
      localStorage.clear()
    } catch (error) {
      // TODO: (Add error handling)
    }
  }

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email)

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password)

  doSignInWithToken = (token) => this.auth.signInWithCustomToken(token)

  user = (email) =>
    this.firestore.collection("users").where("email", "==", email)

  onProviderOnlineStatusListener = (providerId) => {
    const providerRef = this.db.ref(`online/${providerId}`)
    providerRef.onDisconnect().set(false)

    const online = this.db.ref("online")
    online.set({
      [providerId]: true,
    })
  }

  handleVerifyPasswordResetCode(actionCode) {
    return this.auth
      .verifyPasswordResetCode(actionCode)
      .then((email) => email)
      .catch((error) => error)
  }

  handleResetPassword(actionCode, newPassword) {
    return this.auth
      .confirmPasswordReset(actionCode, newPassword)
      .then((resp) => resp)
      .catch((error) => error)
  }

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        const snapshot = await this.user(authUser.email).get()
        if (snapshot) {
          const usersData = snapshot.docs.map((d) => ({ ...d.data() }))
          const dbUser = usersData[0]
          // default empty roles
          if (dbUser && !dbUser.roles) {
            dbUser.roles = {}
          }

          // merge auth and db user
          const newAuthUser = {
            uid: authUser.uid,
            email: authUser?.email?.toLowerCase(),
            emailVerified: authUser?.emailVerified,
            providerData: authUser?.providerData,
            userName: get(dbUser, ["medicProfile", "displayName"]),
            medicProfile: dbUser,
          }
          next(newAuthUser)
        }
      } else {
        fallback()
      }
    })

  async login({ email, password }) {
    return this.auth.signInWithEmailAndPassword(email.toLowerCase(), password)
  }

  logout() {
    localStorage.clear()
    this.auth.signOut()
  }

  async create({ email, password }) {
    return this.auth.createUserWithEmailAndPassword(
      email.toLowerCase(),
      password
    )
  }

  async reset({ email }) {
    return this.auth.sendPasswordResetEmail(email?.toLowerCase())
  }

  async getProfile({ email }) {
    return this.firestore.collection("users").doc(email?.toLowerCase()).get()
  }

  async getBusiness({ email }) {
    return this.firestore
      .collection("users")
      .doc(email)
      .collection("forms")
      .doc("business")
      .get()
  }

  async getAcademic({ email }) {
    return this.firestore
      .collection("users")
      .doc(email)
      .collection("forms")
      .doc("academic")
      .get()
  }

  async getFees({ email }) {
    return this.firestore
      .collection("users")
      .doc(email)
      .collection("forms")
      .doc("fees")
      .get()
  }

  async savePersonalData({ email, data }) {
    return this.firestore
      .collection("users")
      .doc(email)
      .set(
        {
          ...data,
          indexDocument: true,
        },
        { merge: true }
      )
  }

  async saveAcademicData({ email, data }) {
    return this.firestore
      .collection("users")
      .doc(email)
      .collection("forms")
      .doc("academic")
      .set(data, {
        merge: true,
      })
  }

  async saveCompanyData({ email, data }) {
    return this.firestore
      .collection("users")
      .doc(email)
      .collection("forms")
      .doc("business")
      .set(data, {
        merge: true,
      })
  }

  async saveFeesData({ email, data }) {
    return this.firestore
      .collection("users")
      .doc(email)
      .collection("forms")
      .doc("fees")
      .set(data, {
        merge: true,
      })
  }

  async saveProviderChargesByDoc({ email, data }) {
    return this.firestore
      .collection("users")
      .doc(email)
      .collection("forms")
      .doc("chargesByDoc")
      .set(data, {
        merge: true,
      })
  }

  async toggleOnlineStatus({ email, data }) {
    return this.firestore.collection("users").doc(email).set(data, {
      merge: true,
    })
  }

  async getOnlineStatus({ email }) {
    const user = await this.firestore.collection("users").doc(email).get()
    return user.data().online
  }

  async getIdToken() {
    return this.auth.currentUser && this.auth.currentUser.getIdToken()
  }

  async getIdTokenResult() {
    return this.auth.currentUser && this.auth.currentUser.getIdTokenResult()
  }

  updateProfile(field, data) {
    this.auth.currentUser.updateProfile({
      [field]: data,
    })
  }

  async updatePassword(newPassword) {
    return this.auth.currentUser.updatePassword(newPassword)
  }

  getCurrentUser() {
    return this.auth.currentUser
  }

  subscribeToAppointments({ doctor, onSnapshot }) {
    const query = this.firestore
      .collection("appointments")
      .where("providerEmail", "==", doctor)
      .where("status", "in", ["confirmed", "rescheduled"])
    return query.onSnapshot(onSnapshot, (err) => {
      console.log(`Encountered error: ${err}`)
    })
  }

  subscribeToProviderStatus({ doctor, onSnapshot }) {
    if (!doctor) return null
    return this.firestore
      .collection("users")
      .doc(doctor)
      .onSnapshot(onSnapshot, (err) => {
        console.log(`Encountered error: ${err}`)
      })
  }

  confirmAppointmentPayment(appointmentId, status) {
    const aptRef = this.firestore.doc(`appointments/${appointmentId}`)
    return aptRef.set(
      {
        paymentStatus: status || "paid",
      },
      { merge: true }
    )
  }

  async addAppointment({ doctor, appointment }) {
    const doctorRef = this.firestore.collection("users").doc(doctor)
    this.firestore
      .collection("users")
      .doc(doctor)
      .collection("appointments")
      .add({
        ...appointment,
        doctor: doctorRef,
      })
  }

  async addContact({ doctor, contact }) {
    this.firestore
      .collection("users")
      .doc(doctor)
      .collection("contacts")
      .add(contact)
  }

  async getContacts(doctor) {
    return this.firestore
      .collection("users")
      .doc(doctor)
      .collection("contacts")
      .get()
  }

  async addTip({
    target,
    tip,
    profile,
    initials,
    session,
    currentTime,
    dependent,
  }) {
    this.firestore
      .collection("users")
      .doc(target)
      .collection("tips")
      .add({
        tip,
        createdAt: currentTime,
        modifiedAt: currentTime,
        provider: profile.title
          ? `${profile.title} ${profile.displayName}`
          : profile.displayName,
        providerEmail: profile.email,
        providerUid: profile.uid,
        providerPhotoURL: profile.photoURL,
        providerInitials: initials,
        chatId: session,
        ...(dependent && {
          dependentContact: dependent.value,
          dependentName: dependent.dependentName,
        }),
      })
  }

  getChats({ doctor, onceValue }) {
    const chatsRef = this.db.ref("sessions")
    chatsRef.orderByChild("providerId").equalTo(doctor).once("value", onceValue)
  }

  getMessages({ chat, messagesLimit, onceValue }) {
    const chatMessagesRef = this.db.ref(`chats/${chat}/messages`)
    chatMessagesRef
      .orderByChild("createdAt")
      .limitToLast(messagesLimit)
      .once("value", onceValue)
  }

  getAllQuestions = async (provider) =>
    this.firestore
      .collection("questions")
      .where("providerEmail", "==", provider)
      .get()

  getPendingAnswerQuestions = async (provider) =>
    this.firestore
      .collection("questions")
      .where("providerEmail", "==", provider)
      .where("status", "==", "submitted")
      .get()

  subscribeToChats() {
    const chatsRef = this.db.ref("chats")
    return chatsRef
  }

  subscribeToMessages({ chat }) {
    const chatMessagesRef = this.db.ref(`chats/${chat}/messages`)
    return chatMessagesRef
  }

  subscribeToSpecificMessage({ chat, messageId }) {
    const chatMessagesRef = this.db.ref(`chats/${chat}/messages/${messageId}`)
    return chatMessagesRef
  }

  async pushMessage({ chat, message }) {
    const chatsRef = this.db.ref(`chats/${chat}`)
    const sessionRef = this.db.ref(`sessions/${chat}`)
    const chatMessagesRef = this.db.ref(`chats/${chat}/messages`)
    const newMessage = await chatMessagesRef.push(message)
    chatsRef.child("modifiedAt").set(message.createdAt)
    sessionRef.child("modifiedAt").set(message.createdAt)
    return newMessage.key
  }

  async updateMessage({ chat, messageId, field, newData }) {
    const chatMessagesRef = this.db.ref(`chats/${chat}/messages/${messageId}`)
    return chatMessagesRef.child(field).set(newData)
  }

  getServerTimeOffsetRef() {
    return this.db.ref(".info/serverTimeOffset")
  }

  finishSession({ chat }) {
    const chatsRef = this.db.ref(`chats/${chat}`)
    const sessionRef = this.db.ref(`sessions/${chat}`)
    chatsRef.child("status").set("finished")
    sessionRef.child("status").set("finished")
  }

  donateSession({ chat }) {
    const chatsRef = this.db.ref(`chats/${chat}`)
    const sessionRef = this.db.ref(`sessions/${chat}`)
    chatsRef.child("donated").set(true)
    sessionRef.child("donated").set(true)
  }

  dismissClientWarning({ chat }) {
    const chatsRef = this.db.ref(`chats/${chat}`)
    const sessionRef = this.db.ref(`sessions/${chat}`)
    chatsRef.child("clientNoShowWarning").set(false)
    sessionRef.child("clientNoShowWarning").set(false)
  }

  async addWaitingMinutes({ chat }) {
    const sessionsRef = this.db.ref("sessions")
    const chatsRef = this.db.ref("chats")
    let currentTimeoutExceptionCount = 1
    await this.db
      .ref(`sessions/${chat}`)
      .once("value")
      .then((session) => {
        const data = session.val()
        if (data.timeoutExceptionCount) {
          const newVal = data.timeoutExceptionCount + 1
          currentTimeoutExceptionCount = newVal
        }
      })
    await sessionsRef.child(chat).update({
      timeoutWarning: false,
      timeoutExceptionCount: currentTimeoutExceptionCount,
    })
    await chatsRef.child(chat).update({
      timeoutWarning: false,
      timeoutExceptionCount: currentTimeoutExceptionCount,
    })
  }

  async getOrganizations(type) {
    return this.firestore
      .collection("organizations")
      .where("type", "==", type)
      .where("active", "==", true)
      .get()
  }

  async getOrgLocationList(orgId) {
    const result = []
    try {
      const locations = await this.firestore
        .collection("organizations")
        .doc(orgId)
        .collection("locations")
        .get()

      if (locations && locations.docs.length > 0) {
        locations.docs.forEach((location) => {
          result.push({
            id: location.id,
            ...location.data(),
          })
        })
      }
    } catch (error) {
      console.log("error:", error)
    }
    return result
  }

  async getOrgDepartmentByLocation(orgId, locationId) {
    const result = []
    try {
      const departments = await this.firestore
        .collection("organizations")
        .doc(orgId)
        .collection("locations")
        .doc(locationId)
        .collection("departments")
        .get()

      if (departments && departments.docs.length > 0) {
        departments.docs.forEach((department) => {
          result.push({
            id: department.id,
            ...department.data(),
          })
        })
      }
    } catch (error) {
      console.log("error:", error)
    }
    return result
  }

  async getOrganization(orgId) {
    return this.firestore.collection("organizations").doc(orgId).get()
  }

  async getAgents(pharma) {
    return this.firestore
      .collection("organizations")
      .doc(pharma)
      .collection("agents")
      .where("active", "==", true)
      .get()
  }

  uploadMediaFile({ uploadDir, fileName, file }) {
    return this.storage.ref(`${uploadDir}/${fileName}`).put(file)
  }

  uploadProviderFile({ uploadDir, fileName, file }) {
    return this.storage
      .ref(`${uploadDir}/${fileName}`)
      .putString(file, "data_url")
  }

  getMediaURL({ fileName, uploadDir }) {
    return this.storage.ref(uploadDir).child(fileName).getDownloadURL()
  }

  uploadToStorage(name, blob) {
    return this.storage.ref().child(name).put(blob)
  }

  async getFormOptions(type = "bureaus") {
    return this.firestore.collection("formOptions").doc(type).get()
  }

  async getBureausByCountry(country = "CR") {
    return this.firestore
      .collection("formOptions")
      .doc("bureaus")
      .collection("countries")
      .doc(country)
      .get()
  }

  async getBanksByCountry(country = "CR") {
    return this.firestore
      .collection("formOptions")
      .doc("banks")
      .collection("countries")
      .doc(country)
      .get()
  }

  async getDashboard(email) {
    return this.firestore
      .collection("transactions")
      .where("metadata.providerEmail", "==", email)
      .where("createdAt", ">=", moment().startOf("month").valueOf())
      .where("createdAt", "<=", moment().endOf("month").valueOf())
      .where("captured", "==", true)
      .get()
  }

  async getCashDashboard(email) {
    return this.firestore
      .collection("appointments")
      .where("providerEmail", "==", email)
      .where("modifiedAt", ">=", moment().startOf("month").valueOf())
      .where("modifiedAt", "<=", moment().endOf("month").valueOf())
      .where("type", "==", "faceToFace")
      .where("status", "==", "finished")
      .get()
  }

  async getDashboardCaptures(email) {
    return this.firestore
      .collection("captures")
      .where("providerEmail", "==", email)
      .where("createdAt", ">=", moment().startOf("month").valueOf())
      .where("createdAt", "<=", moment().endOf("month").valueOf())
      .get()
  }

  getUsersByType = async (type, country) =>
    this.firestore
      .collection("users")
      .where("userType", "==", type)
      .where("country", "==", country)
      .get()

  getUsersByActivationToken = async (token) =>
    this.firestore
      .collection("users")
      .where("activationToken", "==", token)
      .get()

  updateProvider = async (data, email) => {
    const userRef = this.firestore.doc(`users/${email}`) // email
    return userRef.set(
      {
        ...data,
        indexDocument: true,
      },
      { merge: true }
    )
  }

  getAppointmentByChat = async (chatId) => {
    const dsAppointments = this.firestore.collection("appointments")
    return dsAppointments.where("chatId", "==", chatId).get()
  }

  getUpcomingAppointments = async (email) => {
    const dsAppointments = this.firestore.collection("appointments")
    return dsAppointments
      .where("providerEmail", "==", email)
      .where("status", "in", ["pending", "confirmed", "rescheduled"])
      .get()
  }

  saveProviderSettings = async (data, key, email) =>
    this.firestore
      .collection("users")
      .doc(email)
      .collection("settings")
      .doc(key)
      .set(data, {
        merge: true,
      })

  getSettings = async ({ email, key }) =>
    this.firestore
      .collection("users")
      .doc(email)
      .collection("settings")
      .doc(key)
      .get()

  upsertMedicalAssistantProvider = async ({ providerEmail, assistantData }) =>
    this.firestore
      .collection("users")
      .doc(providerEmail)
      .collection("assistants")
      .doc(assistantData.email)
      .set(assistantData, {
        merge: true,
      })

  saveAssistantData = async ({ email, data, provider }) => {
    const newUserRef = this.firestore.doc(`users/${email}`)
    await newUserRef.set(
      {
        ...data,
        indexDocument: true,
      },
      { merge: true }
    )

    return this.firestore
      .collection("users")
      .doc(email)
      .collection("providers")
      .doc(provider?.uid)
      .set(
        {
          displayName: provider?.displayName,
          email: provider?.email,
          photoURL: provider?.photoURL,
          profession: provider?.profession,
          title: provider?.title,
          name: provider?.name || null,
          surname1: provider?.surname1 || null,
          surname2: provider?.surname2 || null,
        },
        {
          merge: true,
        }
      )
  }

  getAssistants = async ({ email }) =>
    this.firestore.collection("users").doc(email).collection("assistants").get()

  onDeleteAssistant = async (email, providerEmail) => {
    await this.firestore
      .collection("users")
      .doc(providerEmail)
      .collection("assistants")
      .doc(email)
      .delete()

    const existingAssistant = this.firestore.collection("users").doc(email)
    return existingAssistant.delete()
  }

  // This deletes the assistant just from the provider, not for all the providers assigned
  onDeleteAssistantOneProvider = async (email, providerEmail) =>
    this.firestore
      .collection("users")
      .doc(providerEmail)
      .collection("assistants")
      .doc(email)
      .delete()

  // This remove the provider from the assistants collection
  deleteAssistantProvider = async (email, uid) =>
    this.firestore
      .collection("users")
      .doc(email)
      .collection("providers")
      .doc(uid)
      .delete()

  getAssistantsProviders = async (email) =>
    this.firestore.collection("users").doc(email).collection("providers").get()

  updateAssistant = async (data, email) =>
    this.firestore.collection("users").doc(email).set(data, {
      merge: true,
    })

  getMedicalForm = async (email) =>
    this.firestore
      .collection("users")
      .doc(email)
      .collection("forms")
      .doc("medical")
      .get()

  getDependents = async (email) =>
    this.firestore.collection("users").doc(email).collection("dependents").get()

  getNotes = async (doctor, contact) =>
    this.firestore
      .collection("users")
      .doc(doctor)
      .collection("contacts")
      .doc(contact)
      .collection("notes")
      .get()

  async AddMedicalNotes({ doctor, contact, medicalNote }) {
    this.firestore
      .collection("users")
      .doc(doctor)
      .collection("contacts")
      .doc(contact)
      .collection("notes")
      .add(medicalNote)
  }

  async AddMedicalDocument({ doctor, contact, document }) {
    this.firestore
      .collection("users")
      .doc(doctor)
      .collection("contacts")
      .doc(contact)
      .collection("documents")
      .add(document)
  }

  async updateMedicalNote({ doctor, contact, noteId, medicalNote }) {
    this.firestore
      .collection("users")
      .doc(doctor)
      .collection("contacts")
      .doc(contact)
      .collection("notes")
      .doc(noteId)
      .set(medicalNote, {
        merge: true,
      })
  }

  async addResult({ type, id, resultUrl }) {
    this.firestore.collection(type).doc(id).set(
      { resultUrl },
      {
        merge: true,
      }
    )
  }

  async addMedicalRecord({ contactEmail, record }) {
    this.firestore
      .collection("users")
      .doc(contactEmail)
      .collection("medicalFollowUp")
      .add(record)
  }

  async updateMedicalRecord({ contactEmail, record, recordId }) {
    this.firestore
      .collection("users")
      .doc(contactEmail)
      .collection("medicalFollowUp")
      .doc(recordId)
      .set(record, {
        merge: true,
      })
  }

  deleteMedicalRecord = async ({ contactEmail, recordId }) =>
    this.firestore
      .collection("users")
      .doc(contactEmail)
      .collection("medicalFollowUp")
      .doc(recordId)
      .delete()

  getMedicalRecords = async (contactEmail) =>
    this.firestore
      .collection("users")
      .doc(contactEmail)
      .collection("medicalFollowUp")
      .orderBy("createdAt", "desc")
      .get()

  checkContact = async (doctorEmail, contactEmail) =>
    this.firestore
      .collection("users")
      .doc(doctorEmail)
      .collection("contacts")
      .where("email", "==", contactEmail)
      .where("userType", "==", "client")
      .get()

  getUserContactData = async (contactEmail) =>
    this.firestore.collection("users").doc(contactEmail).get()

  getDependentProfile = async (contactEmail, dependentName) =>
    this.firestore
      .collection("users")
      .doc(contactEmail)
      .collection("dependents")
      .where("dependentName", "==", dependentName)
      .get()

  getPricing = () =>
    this.firestore.collection("environment").doc("settings/doc/pricing").get()

  getPrescriptions = async (clientEmail, provider) =>
    this.firestore
      .collection("prescriptions")
      .where("client", "==", clientEmail)
      .where("provider", "==", provider)
      .get()

  getRecommendations = async (clientEmail, provider) =>
    this.firestore
      .collection("recommendations")
      .where("client", "==", clientEmail)
      .where("provider", "==", provider)
      .get()

  getMedicalImages = async (clientEmail, provider) =>
    this.firestore
      .collection("medicalImages")
      .where("client", "==", clientEmail)
      .where("provider", "==", provider)
      .get()

  getClinical = async (clientEmail, provider) =>
    this.firestore
      .collection("clinical")
      .where("client", "==", clientEmail)
      .where("provider", "==", provider)
      .get()

  getDocuments = async (providerEmail, clientId) =>
    this.firestore
      .collection("users")
      .doc(providerEmail)
      .collection("contacts")
      .doc(clientId)
      .collection("documents")
      .get()

  getInstructions = async (clientEmail) =>
    this.firestore.collection("users").doc(clientEmail).collection("tips").get()

  getUserCards = async (providerEmail) =>
    this.firestore
      .collection("users")
      .doc(providerEmail)
      .collection("tokens")
      .get()

  async createOdontogramRecord({ clientEmail, record }) {
    this.firestore
      .collection("users")
      .doc(clientEmail)
      .collection("odontogram")
      .add(record)
  }

  getClientOdontogram = async ({ clientEmail }) =>
    this.firestore
      .collection("users")
      .doc(clientEmail)
      .collection("odontogram")
      .orderBy("createdAt", "desc")
      .get()

  async updateOdontogramRecord({ clientEmail, record, recordId }) {
    this.firestore
      .collection("users")
      .doc(clientEmail)
      .collection("odontogram")
      .doc(recordId)
      .update(record)
  }

  getCauses = () => this.firestore.collection("causes").get()

  getDiagnoses = () => this.firestore.collection("diagnoses").get()

  createCauseOrDiagnose({ collection, record }) {
    this.firestore.collection(collection).add(record)
  }

  getGlobalVar = (name) => {
    // const urlScheme = this.remoteConfig.getString("app__urlScheme")
    // const firebaseProject = this.remoteConfig.getString("app__firebaseProject")
    // const docLanding = this.remoteConfig.getString("web__docLanding")
    // const docWebsite = this.remoteConfig.getString("web__docWebsite")
    // const baseUrl = `${urlScheme}://${firebaseProject}`

    let varValue = null

    switch (name) {
      /**
       * Application Variables
       * IMPORTANT: Keep this section at the top
       */
      case "allowedRoles":
        varValue = this.remoteConfig.getString("app__allowedRoles").split(",")
        break
      case "preferredCountries":
        varValue = this.remoteConfig
          .getString("app__preferredCountries")
          .split(",")
        break
      case "supportedLanguages":
        varValue = this.remoteConfig
          .getString("app__supportedLanguages")
          .split(",")
        break
      case "searchResultsCount":
        varValue = this.remoteConfig.getString("app__searchResultsCount")
        break
      case "searchResultsIncrement":
        varValue = this.remoteConfig.getString("app__searchResultsIncrement")
        break
      case "supportEmail":
        varValue = this.remoteConfig.getString("app__supportEmail")
        break
      case "slotDuration":
        varValue = this.remoteConfig.getString("app__slotDuration")
        break
      default:
        break
    }

    return varValue
  }

  version = () => "1.0.0"
}

export default Firebase
